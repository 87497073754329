import React, { useEffect, useState } from 'react';
import { Facet } from '../../../model';
import { DropdownItem } from '~/shared/components';
import {
    MultiThumbSlider,
    MultiThumbSliderOnChangeProps,
} from '~/shared/components/FormElements/components/MultiThumbSlider';
import { useFrame } from '~/shared/utils';
import getRangeOptions from './utils/getRangeOptions';
import { useTranslation } from '~/shared/utils/translation';
import { RangeItem } from '~/shared/components/MultipleSelector/model';
import { TranslationKey } from '~/lib/data-contract';
import { FILTER_DEBOUNCE } from '../utils';
import { useProductsStore } from '../../hooks';

export type MultiRangeFilterProps = {
    facet: Facet;
    selectedItems?: RangeItem[];
    onChange: (
        event: React.ChangeEvent<HTMLInputElement> | undefined,
        facet: Facet,
        item?: DropdownItem,
    ) => void;
};

export const MultiRangeFilter = ({ facet, selectedItems, onChange }: MultiRangeFilterProps) => {
    let debounce: NodeJS.Timeout;
    const { data: frame } = useFrame();
    const { translate } = useTranslation();

    const label = facet?.label?.match(/([^_]+)/)?.[1] || '';

    const { removeFacetSelection } = useProductsStore();

    const { prependMax, prependMin, appendMax, appendMin, maxValueRange, minValueRange, step } =
        getRangeOptions({
            facetKey: label,
            frame,
            translate,
        });

    const [values, setValues] = useState({
        minValue: selectedItems ? selectedItems[0]?.value?.lowerBoundInclusive : minValueRange,
        maxValue:
            selectedItems && selectedItems[0]?.value?.upperBoundExclusive
                ? selectedItems[0].value.upperBoundExclusive - 1
                : maxValueRange,
    });

    useEffect(() => {
        setValues({
            minValue: selectedItems ? selectedItems[0]?.value?.lowerBoundInclusive : minValueRange,
            maxValue:
                selectedItems && selectedItems[0]?.value?.upperBoundExclusive
                    ? selectedItems[0].value.upperBoundExclusive - 1
                    : maxValueRange,
        });
    }, [selectedItems]);

    useEffect(() => {
        return () => {
            clearTimeout(debounce);
        };
    }, []);

    function handleOnChange({ minValue, maxValue }: MultiThumbSliderOnChangeProps) {
        clearTimeout(debounce);

        debounce = setTimeout(() => {
            const { minValue: currentMinValue, maxValue: currentMaxValue } = values;

            if (minValue !== currentMinValue || maxValue !== currentMaxValue) {
                if (minValue === minValueRange && maxValue === maxValueRange) {
                    removeFacetSelection(facet.attribute);
                } else {
                    onChange(undefined, facet, {
                        value: {
                            lowerBoundInclusive: minValue,
                            upperBoundExclusive:
                                maxValueRange !== maxValue ? maxValue + 1 : undefined,
                        },
                        label: `[${minValue} - ${maxValue}]`,
                    });
                }
            }
        }, FILTER_DEBOUNCE.DEBOUNCE);
    }

    return (
        <div>
            <MultiThumbSlider
                maxValueRange={maxValueRange}
                minValueRange={minValueRange}
                prependMin={prependMin}
                prependMax={prependMax}
                appendMin={appendMin}
                appendMax={appendMax}
                step={step}
                label={facet.label}
                invalidMessage={translate(
                    `Kompan.Commerce.Filters${label}ErrorMessage` as TranslationKey,
                )}
                labelRangeFrom={translate(`Kompan.Commerce.Filters${label}From` as TranslationKey)}
                labelRangeTo={translate(`Kompan.Commerce.Filters${label}To` as TranslationKey)}
                onChange={handleOnChange}
                minValue={values.minValue}
                maxValue={values.maxValue}
            />
        </div>
    );
};
